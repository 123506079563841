<template>
  <CFooter :fixed="false">
    <div>
      <CIcon :height="12" :content="cilApplications" class="mr-1"/>{{ $t("common.footer_left",{obj:get_business()})}}
    </div>
    <div class="mfs-auto">
      <span class="mr-1" target="_blank">{{ $t("common.footer_right",{obj:"Super Team"})}}</span>
    </div>
  </CFooter>
</template>

<script>
import { Business } from '../helpers/business'
import { cilApplications} from '@coreui/icons'
export default {
  name: 'TheFooter',
  setup() {
    return {
      cilApplications
    }
  },
  methods: { 
    get_business(){
      let user= this.$store.state.st_authentication.user
      if(user==null) return ''

      let business=user.company.business
      return business[0].toUpperCase() + business.slice(1)
    }
  }
}
</script>
