<template>
  <CDropdown 
    placement="bottom-end"
    :caret="true"
    in-nav
    class="c-header-nav-item d-md-down-none mx-2"
    add-menu-classes="pt-0"
  >
  
    <template #toggler>
      <CHeaderNavLink>
        <CIcon :height="25" :content="cilLanguage"/>
      </CHeaderNavLink>
    </template>
    <CDropdownItem @click="callSetLangActions('vi')">
      <CIcon :height="12" :content="cifVn"/> VietNam
    </CDropdownItem>
    <CDropdownItem @click="callSetLangActions('en')">
      <CIcon :height="12" :content="cifGb"/> English
    </CDropdownItem>
  </CDropdown>
  
</template>
<script>
import { cilLanguage, cifGb, cifVn } from '@coreui/icons'
export default {
  name: 'TheHeaderDropdownLang',
  data () {
    return { itemsCount: 5 }
  },
  setup() {
    return {
      cilLanguage,
      cifGb,
      cifVn
    }
  }
}
</script>

<style scoped>
  .c-icon {
    margin-right: 0.3rem;
  }
</style>