<template>
  <CDropdown
    inNav
    class="c-header-nav-items"
    placement="bottom-end"
    add-menu-classes="pt-0"
  >
    <template #toggler>
      <CHeaderNavLink>
        <div class="c-avatar">
          <img v-if="user_login&&user_login.logo" :src="computed_logo(user_login.logo)"
            class="img-avatar img-circle img-thumbnail"/>
          <img v-else src="http://ssl.gstatic.com/accounts/ui/avatar_2x.png"
            class="avatar img-circle img-thumbnail" alt="avatar">
        </div>
        
      </CHeaderNavLink>
    </template>
    <!-- <CDropdownHeader tag="div" class="text-center" color="light">
      <strong>Account</strong>
    </CDropdownHeader>
    <CDropdownItem>
      <CIcon name="cil-bell" @click="update_prodile()"/> Updates
      <CBadge color="info" class="mfs-auto">{{ itemsCount }}</CBadge>
    </CDropdownItem>
    <CDropdownItem>
      <CIcon name="cil-envelope-open" /> Messages
      <CBadge color="success" class="mfs-auto">{{ itemsCount }}</CBadge>
    </CDropdownItem>
    <CDropdownItem>
      <CIcon name="cil-task" /> Tasks
      <CBadge color="danger" class="mfs-auto">{{ itemsCount }}</CBadge>
    </CDropdownItem>
    <CDropdownItem>
      <CIcon name="cil-comment-square" /> Comments
      <CBadge color="warning" class="mfs-auto">{{ itemsCount }}</CBadge>
    </CDropdownItem> -->
    <CDropdownHeader
      tag="div"
      class="text-center"
      color="light"
    >
      <strong>Settings</strong>
    </CDropdownHeader>
    <CDropdownItem @click="update_profile()">
      <CIcon name="cil-user"/> Profile
    </CDropdownItem>
    <CDropdownItem @click="update_setting()">
      <CIcon name="cil-settings" /> Settings
    </CDropdownItem>
    <!-- <CDropdownItem>
      <CIcon name="cil-dollar" /> Payments
      <CBadge color="secondary" class="mfs-auto">{{ itemsCount }}</CBadge>
    </CDropdownItem> -->
    <!-- <CDropdownItem>
      <CIcon name="cil-file" /> Projects
      <CBadge color="primary" class="mfs-auto">{{ itemsCount }}</CBadge>
    </CDropdownItem> -->
    <CDropdownDivider/>
    <!-- <CDropdownItem>
      <CIcon name="cil-shield-alt" /> Lock Account
    </CDropdownItem> -->
    <CDropdownItem @click="logout()">
      <CIcon name="cil-lock-locked"/> {{$t("common.logout")}}
    </CDropdownItem>
  </CDropdown>
</template>

<script>
export default {
  name: 'TheHeaderDropdownAccnt',
  data () {
    return { 
      itemsCount: 42
    }
  },
  computed: {
    user_login () {
      debugger
      let user= this.$store.state.st_authentication.user
      if (user==null) return this.$router.push('/home')
      debugger
      return user
    }
  },
  methods:{
    update_profile(){
      this.$router.push('/system/profile')
    },
    update_setting(){
      this.$router.push('/system/setting')
    },
    async logout(){
      debugger
      let vm =this
      let val= await this.$store.dispatch('st_authentication/log_logout',
      {user_id:vm.user_login.id,company_id:vm.user_login.company_id,
      branch_id:vm.user_login.branch_id,business:vm.user_login.company.business})
      debugger
      if(val && val.data){
        this.$store.dispatch('st_authentication/do_logout')
        .then(() => {
          this.$store.commit('st_authentication/logout')
          this.$router.push('/')
        })
      }else{
        this.$toastr.error(this.$t("common.error_message"),this.$t("common.message"))
      }
    }
  }
}
</script>

<style scoped>
  .c-icon {
    margin-right: 0.3rem;
  }
</style>