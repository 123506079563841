<template>
  <CHeader with-subheader>
    <CToggler
      in-header
      class="ml-3 d-lg-none"
      @click="$store.commit('st_responsive/toggleSidebarMobile')"
    />
    <CToggler
      in-header
      class="ml-3 d-md-down-none"
      @click="$store.commit('st_responsive/toggleSidebarDesktop')"
    />
    <CHeaderBrand class="mx-auto d-lg-none" to="/">
      <CIcon name="logo" height="48" alt="Logo"/>
    </CHeaderBrand>
    <CHeaderNav class="d-md-down-none mr-auto">
      <CHeaderNavItem class="px-3">
        <CHeaderNavLink :to ="'/'+user_login.company.business">
          <CIcon :height="15" :content="cilHome" class="mr-1"/>Dashboard
        </CHeaderNavLink>
      </CHeaderNavItem>
      <CHeaderNavItem class="px-3">
        <CHeaderNavLink :to="'/'+user_login.company.business+'/ls-user'" exact>
          <CIcon :height="15" :content="cilUser" class="mr-1"/>Users
        </CHeaderNavLink>
      </CHeaderNavItem>
      <CHeaderNavItem class="px-3">
        <CHeaderNavLink :to="'/'+user_login.company.business+'/setting'">
          <CIcon :height="15" :content="cilSettings" class="mr-1"/>Settings
        </CHeaderNavLink>
      </CHeaderNavItem>
    </CHeaderNav>
    <CHeaderNav>
      <!-- <CHeaderNavItem class="px-3 c-d-legacy-none">
        <button
          @click="() => $store.commit('st_responsive/toggle', 'darkMode')"
          class="c-header-nav-btn"
        >
          <CIcon v-if="$store.state.st_responsive.darkMode" name="cil-sun"/>
          <CIcon v-else name="cil-moon"/>
        </button>
      </CHeaderNavItem> -->
      <!-- <TheHeaderDropdownNotif/> -->
      <!-- <TheHeaderDropdownTasks/> -->
      <!-- <TheHeaderDropdownMssgs/> -->
       <TheHeaderDropdownLang/>
      <TheHeaderDropdownAccnt/>
      <!-- <CHeaderNavItem class="px-3">
        <button
          class="c-header-nav-btn"
          @click="$store.commit('st_responsive/toggle', 'asideShow')"
        >
          <CIcon size="lg" name="cil-applications-settings" class="mr-2"/>
        </button>
      </CHeaderNavItem> -->
    </CHeaderNav>

    <!-- <CSubheader class="px-3">
      <CBreadcrumbRouter class="border-0 mb-0"/>
    </CSubheader> -->
  </CHeader>
</template>

<script>
import TheHeaderDropdownAccnt from './TheHeaderDropdownAccnt'
// import TheHeaderDropdownNotif from './TheHeaderDropdownNotif'
// import TheHeaderDropdownTasks from './TheHeaderDropdownTasks'
import TheHeaderDropdownLang from './TheHeaderDropdownLang'
import { cilHome,cilUser,cilSettings } from '@coreui/icons'

export default {
  name: 'TheHeader',
  components: {
    TheHeaderDropdownAccnt,
    TheHeaderDropdownLang
    // TheHeaderDropdownNotif,
    // TheHeaderDropdownTasks,
    // TheHeaderDropdownMssgs
  },

  setup() {
    return {
      cilHome,
      cilUser,
      cilSettings
    }
  },
  methods: { 
    update_setting(){
      this.$router.push('/system/setting')
    },
  },
  computed: { 
    user_login() {
      return this.$store.state.st_authentication.user
    }
  }
}
</script>
